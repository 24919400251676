import React from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Content from "../components/Content";
import PostList from "../components/PostList";
import MediaBar from "../components/MediaBar";
import Paginator from "../components/Paginator";
import nodeToPost from "../utils/nodeToPost";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default (props) => {
  const { group, index, pageCount } = props.pageContext;
  const posts = group.map((edge) => nodeToPost(edge.node));

  const buildPath = (index) => (index > 1 ? `/blog/${index}` : `/`);

  return (
    <Layout>
      <Seo
        url={`https://tleef.com${buildPath(index)}`}
        keywords={[
          `blog`,
          `business`,
          `entrepreneurship`,
          `software`,
          `technology`,
          `engineering`,
        ]}
      />
      <Container>
        <Header />
        <Content>
          <Grid container spacing={40}>
            <Grid item xs={12} md={8}>
              <PostList posts={posts} title={"Recent Posts"} />
              <Paginator
                index={index}
                pageCount={pageCount}
                buildPath={buildPath}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MediaBar />
            </Grid>
          </Grid>
        </Content>
        <Footer />
      </Container>
    </Layout>
  );
};
